/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
  url(./assets/fonts/Montserrat/Montserrat-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "MontserratBold";
  src: local("MontserratBold"),
  url(./assets/fonts/Montserrat/Montserrat-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "MontserratThin";
  src: local("MontserratThin"),
  url(./assets/fonts/Montserrat/Montserrat-Thin.ttf) format("truetype");
}

@font-face {
  font-family: "MontserratLight";
  src: local("MontserratLight"),
  url(./assets/fonts/Montserrat/Montserrat-Light.ttf) format("truetype");
}

@font-face {
  font-family: "MontserratSemiBold";
  src: local("MontserratSemiBold"),
  url(./assets/fonts/Montserrat/Montserrat-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "MontserratBlack";
  src: local("MontserratBlack"),
  url(./assets/fonts/Montserrat/Montserrat-Black.ttf) format("truetype");
}

@font-face {
  font-family: "MontserratExtraBold";
  src: local("MontserratExtraBold"),
  url(./assets/fonts/Montserrat/Montserrat-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "MontserratExtraLight";
  src: local("MontserratExtraLight"),
  url(./assets/fonts/Montserrat/Montserrat-ExtraLight.ttf) format("truetype");
}

@font-face {
  font-family: "MontserratMedium";
  src: local("MontserratMedium"),
  url(./assets/fonts/Montserrat/Montserrat-Medium.ttf) format("truetype");
}


:root {
  --green-color: #00b8a8;
  --green-color-dark: #008478;
  --blue-color: rgb(0, 27, 149);
  --blue-color-dark: rgb(0, 27, 149);
  --background-gray: #f2f2f2;
  --text-over-green: whitesmoke;
  --background-highlight-grey: rgb(243, 243, 243);
  --login-background: rgb(249, 249, 249);
  --gray-login-background: rgba(48, 49, 49, 1);
  --main-color: #242727;
  --title-grey: rgb(125, 134, 169);
  --title: #252525;
  --detail: rgb(57, 57, 57);
  --selected-item-color: rgba(0, 94, 255, 0.755);
  --unselected-item-color: rgb(0, 92, 255, 40%);
  --hover-color: #6FD8C1;
  --light-blue: #005cff;
  --red-color: #f64a3f;
  --green: #51c03e;
  --card-grey: #f1f6ff;
  --button-grey: #c5c5c5;
  --progress-icon: #dde6f7;
  --button-blue: #1676ff;
  --disabled-green: rgb(176, 210, 206);
  --progress-icon: #dde6f7;
  --menu-item-grey: #9ba4b0;
  --hover-item-grey: #3f4041;
  --background-blue: #001b95;
  --background-green: #00b9a8;
  --light-green: rgba(32, 35, 35, 0.15);
  --extra-light-green: #f0f7f6;
  --background-green-light: #89e8da;
  --search-color: #7d86a9;
  --error-box: rgb(255, 227, 230);
  --error-box-border: rgb(236, 188, 191);
  --background: #fff;
  --background-highlight: #fafafa;
  --background-disabled: #f9f9f986;
}

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat" !important;
  background-color: var(--login-background);
  overflow-x: hidden;
  min-height: 100%;
}

@media screen and (min-height: 900px) {
  body {
    height: 100%;
  }
}

@media screen and (min-width: 0px) and (max-width: 1300px) {
  body {
    overflow-x: visible;
  }
}

@media screen and (max-width: 1430px) {
  table {
    min-width: 1060px;
  }
}

@media screen and (max-width: 1430px) {
  .form-step table {
    min-width: 0;
  }
}

.mat-button-focus-overlay {
  background-color: transparent !important;
}

.green-snackbar {
  background-color: var(--green);
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  max-width: 100% !important;
  width: 100% !important;
  margin: 0 !important;
  height: 64px;
  overflow: visible;
  z-index: 3;
}

.mat-simple-snackbar {
  font-size: 14px !important;
  font-weight: 900 !important;
  margin-top: 8px;
  margin-left: 14px;
  font-family: "MontserratLight";
}

.red-snackbar {
  background-color: var(--red-color);
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  max-width: 100% !important;
  width: 100% !important;
  margin: 0 !important;
  height: 64px;
  overflow: visible;
  z-index: 3;
}

.button.mat-fab {
  width: 36px;
  height: 36px;
  min-width: 36px;
  border-radius: 50%;
  box-shadow: none;
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
}

.dialog-nav-buttons .button.mat-fab {
  margin-right: 0;
  background-color: var(--green-color);
  width: 40px;
  height: 40px;
}

.confirm-buttons .button.mat-fab {
  width: 40px;
  height: 40px;
}

.dialog-nav-buttons .mat-icon.material-icons {
  transform: scale(1.5) !important;
}

.stepper-icon {
  font-size: 14px !important;
  height: 14px !important;
  width: 14px !important;


}

.mat-fab .mat-button-wrapper {
  padding: 0 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;

}


.red-color.mat-fab {
  background-color: var(--green-color);
}

.white-color.mat-fab {
  background-color: white;
}

.green-color.mat-fab {
  background-color: var(--green-color);
}

.button:hover, .add-button:hover, .green-button:hover {
  transition: 0.5s ease-in-out;
  background: var(--hover-color) !important;
}

.button:hover svg {
  stroke-dashoffset: -480;
}

.action-button-colored-icon {
  color: var(--green-color);
  font-size: 20px;
  display: flex;
  align-items: center;
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.flex-container-vertical {
  display: flex;
  flex-direction: column;
  max-width: 15vw;
  margin: 10px 20px;
  overflow: scroll;
  max-height: 80px;
}


.details-package-large table {
  margin: 10px 20px;
  width: 100%;
}

/* DIALOGS */

.mat-dialog-container {
  border-radius: 22px !important;
}

.dialog-button {
  margin-top: 10px !important;
  margin-left: 10px !important;
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
  display: inline;
}

.dialog-title {
  color: #1f1c23;
  font-size: 23px;
  line-height: 1;
  margin-bottom: 0 !important;
  margin-left: 15px !important;
  font-family: "Montserrat";
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
}

.title {
  margin-left: 12px;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inner-title-container {
  display: flex;
  align-items: center;
}

.title .dialog-button {
  margin: 0;
}

.exit-button {
  background-color: transparent;
  border: none;
  outline: none;
  position: relative;
  top: -25px;
  left: 10px;
}

.exit-icon {
  font-size: 24px;
  color: #999999;
}

.mat-dialog-container {
  padding: 0 !important;
}


.medium-dialog > .mat-dialog-container {
  padding: 45px !important;
  background-color: var(--background);
}

.upgrade-bundle-dialog > .mat-dialog-container {
  padding: 58px !important;
  background-color: var(--background);
}


.create-row {
  display: flex;
  justify-content: center;
  gap: 20px;
  text-align: left;
}

.edit-modal-inside-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px 20px;
}

.edit-modal-inside-content-one-field {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px 20px;
}


.create-row-align-start {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  text-align: left;
}

.edit-first-row {
  text-align: center;
  margin-top: 6%;
}

.edit-row {
  text-align: center;
}

.div-btn-save {
  text-align: center;
  margin-top: 20px;
}

.dialog-nav-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.form-step {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  overflow-y: scroll;
  gap: 10px;
}

.nav-buttons-verify {
  width: 100%;
  text-align: right;
  margin-left: 12px;
  margin-top: 16px;
}

/* STEPPER */

.stepper-icon.mat-icon.material-icons {
  font-size: 12px;
  display: block;
}

.mat-horizontal-stepper-header-container {
  margin-top: 30px;
  width: 90%;
  align-self: center;
}

.mat-horizontal-stepper-header .mat-step-icon {
  transform: scale(2.15);
}

.mat-step-header .mat-step-icon-selected {
  background-color: var(--green-color);
}

.mat-step-header .mat-step-icon-state-edit {
  background-color: var(--green-color);
}

.mat-step-header .mat-step-icon-state-done {
  background-color: var(--green-color);
}

.mat-step-header .mat-step-icon-state-number {
  background-color: var(--green-color);
}

.mat-step-header .mat-step-icon {
  background-color: var(--disabled-green);
}


.mat-horizontal-content-container {
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  padding: 24px 0px !important;
  padding-bottom: 0px !important;
}

.horizontal-stepper {
  display: flex;
  align-items: center;
  width: 100% !important;
  flex-grow: 1 !important;
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  background-color: transparent;
}

.short-stepper .mat-horizontal-stepper-header-container {
  width: 67% !important;
}

.very-short-stepper .mat-horizontal-stepper-header-container {
  width: 56% !important;

}


.stepper-item-icon, .confirm-buttons .mat-icon {
  transform: scale(0.9) !important;
}

mat-horizontal-stepper .mat-form-field-flex,
.mat-dialog-container .mat-form-field-flex {
  border-radius: 12px !important;
}

.mat-form-field-underline {
  width: 0% !important;
}

.mat-horizontal-stepper-content {
  height: 100%;
}

.mat-horizontal-content-container {
  flex-grow: 1;
}

mat-horizontal-stepper input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px var(--background-highlight) inset !important;
  box-shadow: 0 0 0px 1000px var(--background-highlight) inset !important;
}

.last-edited-step-1
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(1)::after,
.last-edited-step-1
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(3)::before,
.last-edited-step-2
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(1)::after,
.last-edited-step-2
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(3)::before,
.last-edited-step-2
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(3)::after,
.last-edited-step-2
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(5)::before,
.last-edited-step-3
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(1)::after,
.last-edited-step-3
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(3)::before,
.last-edited-step-3
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(3)::after,
.last-edited-step-3
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(5)::before,
.last-edited-step-3
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(5)::after,
.last-edited-step-3
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(7)::before,
.last-edited-step-4
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(1)::after,
.last-edited-step-4
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(3)::before,
.last-edited-step-4
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(3)::after,
.last-edited-step-4
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(5)::before,
.last-edited-step-4
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(5)::after,
.last-edited-step-4
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(7)::before,
.last-edited-step-4
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(7)::after,
.last-edited-step-4
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(9)::before,
.last-edited-step-5
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(1)::after,
.last-edited-step-5
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(3)::before,
.last-edited-step-5
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(3)::after,
.last-edited-step-5
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(5)::before,
.last-edited-step-5
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(5)::after,
.last-edited-step-5
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(7)::before,
.last-edited-step-5
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(7)::after,
.last-edited-step-5
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(9)::before,
.last-edited-step-5
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(9)::after,
.last-edited-step-5
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(11)::before,
.last-edited-step-6
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(1)::after,
.last-edited-step-6
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(3)::before,
.last-edited-step-6
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(3)::after,
.last-edited-step-6
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(5)::before,
.last-edited-step-6
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(5)::after,
.last-edited-step-6
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(7)::before,
.last-edited-step-6
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(7)::after,
.last-edited-step-6
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(9)::before,
.last-edited-step-6
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(9)::after,
.last-edited-step-6
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(11)::before,
.last-edited-step-6
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(11)::after,
.last-edited-step-6
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(13)::before,
.last-edited-step-7
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(1)::after,
.last-edited-step-7
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(3)::before,
.last-edited-step-7
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(3)::after,
.last-edited-step-7
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(5)::before,
.last-edited-step-7
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(5)::after,
.last-edited-step-7
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(7)::before,
.last-edited-step-7
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(7)::after,
.last-edited-step-7
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(9)::before,
.last-edited-step-7
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(9)::after,
.last-edited-step-7
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(11)::before,
.last-edited-step-7
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(11)::after,
.last-edited-step-7
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(13)::before,
.last-edited-step-7
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(13)::after,
.last-edited-step-7
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(15)::before,

  /*  */
.last-edited-step-8
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(1)::after,
.last-edited-step-8
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(3)::before,
.last-edited-step-8
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(3)::after,
.last-edited-step-8
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(5)::before,
.last-edited-step-8
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(5)::after,
.last-edited-step-8
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(7)::before,
.last-edited-step-8
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(7)::after,
.last-edited-step-8
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(9)::before,
.last-edited-step-8
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(9)::after,
.last-edited-step-8
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(11)::before,
.last-edited-step-8
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(11)::after,
.last-edited-step-8
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(13)::before,
.last-edited-step-8
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(13)::after,
.last-edited-step-8
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(15)::before,
.last-edited-step-8
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(15)::after,
.last-edited-step-8
.mat-horizontal-stepper-header-container
.mat-step-header:nth-child(17)::before {
  border-top-color: var(--green-color) !important;
  border-top-width: 2px !important;
}

/* VERIFY */

.verify-small {
  font-family: "Montserrat";
  color: #7d86a9;
  margin-left: 9%;
  width: 600px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 158px;
}

.verify-large {
  font-family: "Montserrat";
  color: black;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 360px;
}

.verify-vertical {
  display: flex;
  flex-direction: column;
  margin: 10px 20px;
  width: 100px;
}

.verify-list {
  color: var(--detail);
  width: -webkit-max-content;
  width: max-content;
  font-family: "MontserratLight";
  font-size: 14px;
  padding: 3px 0px;
}

.details-package-small {
  min-width: 244px;
  width: max-content;
  height: fit-content;
  background-color: var(--card-grey);
  font-family: "Montserrat";
  margin-bottom: 2rem;
  margin-top: 3%;
  border-radius: 8px;
  text-align: left;
  display: flex;
}

.details-package-large {
  height: fit-content;
  padding: 2px 0px;
  background-color: var(--extra-light-green);
  font-family: "Montserrat";
  margin-bottom: 2rem;
  margin-top: 3%;
  border-radius: 8px;
  text-align: left;
}

.details-flex {
  display: flex;
  flex-wrap: wrap;
}

.details-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}


.details-package-large table {
  background: transparent;
}

.details-package-large .mat-row:nth-child(odd) {
  background: transparent;
}

.details-package-large .mat-row:nth-child(even) {
  background: transparent;
}

.details-package-large .mat-header-cell {
  background: transparent;
}

.verify-title {
  color: var(--title-grey);
  margin-right: 686px;
}

.verify-header {
  color: var(--title);
  font-family: "MontserratLight";
}

.verify-value {
  color: var(--detail);
  font-family: "MontserratLight";
}

/* CARDS */

.details-card {
  max-width: 100%;
  height: fit-content !important;
  min-height: fit-content !important;
  font-size: 14px;
  font-family: "Montserrat";
  border: 2px solid var(--background-green-light);
  border-radius: 15px !important;
  box-shadow: none !important;
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
  padding: 10px !important;
  box-sizing: border-box;
}

.details-card .flex-container {
  min-width: 0;
}

.card-header {
  font-family: "MontserratSemiBold";
  color: #4d4d4d;
  width: max-content;
  font-size: 14px;
}

.card-value {
  color: #4d4d4d;;
  width: max-content;
  font-family: "Montserrat";
  font-size: 14px;
  margin-top: 0px;
  max-width: 132px;
  overflow-x: scroll;
}

.card-name {
  font-size: 16px;
  font-family: "Montserrat";
  color: #1f1c23;
}

.card-value-loader {
  color: var(--detail);
  width: max-content;
  font-family: "MontserratLight";
  font-size: 14px;
  margin-top: 0px;
  max-width: 132px;
  max-height: 10px;
  overflow-x: scroll;
}

.tab-bar {
  font-family: "Montserrat" !important;
  margin-top: 12px;
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
}

.tab-lbl {
  font-size: 14px;
  font-family: "MontserratSemiBold" !important;
}

/* MAT overwrite */

.mat-icon.material-icons {
  background-repeat: no-repeat;
  display: inline-flex;
  fill: currentColor;
  height: 24px;
  width: 24px;
}

.table-icon {
  display: block !important;
  font-size: 20px;
  padding-bottom: 2px;
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
}


.mat-tab-label {
  background-color: var(--background-gray);
  margin-left: 5px;
}

.mat-tab-labels :first-child {
  margin-left: 0px;
  border-top-left-radius: 15px;
}

.mat-tab-labels :last-child {
  border-top-right-radius: 15px;
}


.mat-tab-label-active {
  background-color: var(--green-color) !important;
  color: var(--text-over-green) !important;
}

.mat-ink-bar {
  visibility: hidden !important;
}

.mat-tab-label .mat-tab-label-content {
  font-size: 13px;
  font-family: "MontserratMedium" !important;
}

/* search input */
.mat-input-element {
  color: #4d4d4d !important;
  font-family: "MontserratLight" !important;
  font-size: 14px !important;

}

label.mat-form-field-empty.mat-form-field-label {
  color: #999999 !important;
  font-family: "Montserrat" !important;
  font-size: 14px !important;
}

.large-filter mat-icon {
  color: #666666;
}

/* search placeholder */
.mat-form-field {
  font-family: "MontserratLight";
  color: var(--detail);
}

.mat-form-field-flex {
  font-family: "Montserrat" !important;
  color: var(--detail) !important;
  border-radius: 12px !important;
  border-style: solid !important;
  border-width: 1px !important;
  border-color: #E6E6E6 !important;
  background-color: var(--background-highlight) !important;
}

.ng-invalid.ng-touched.mat-form-field .mat-form-field-flex {
  border-color: var(--red-color) !important;
}

.login-input .mat-form-field-flex {
  background-color: transparent !important;
  border-radius: 14px !important;
  height: 45px !important;
  border: 1px solid rgb(108, 107, 109) !important;
}

/* search icon*/
.search-icon {
  font-size: 20px;
  color: var(--detail);
}

.mat-select {
  font-family: "MontserratLight";
  color: var(--detail);
}

.mat-select-panel {
  max-width: fit-content !important;
}

.mat-select-arrow {
  color: #4d4d4d;
}

.mat-option {
  font-family: "Montserrat";
  font-size: 14px;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: unset !important;
  font-family: "MontserratMedium";
}

.mat-fab:disabled {
  background-color: var(--disabled-green) !important;
  color: white !important;
}

.mat-table {
  font-family: "MontserratLight";
}

.mat-header-cell {
  font-family: "MontserratMedium";
  color: var(--main-color);
  font-size: 15px;
  background: #f2f2f2;
}

.mat-row:nth-child(even) {
  background-color: #f2f2f2
}

.mat-row:nth-child(odd) {
  background-color: var(--background-highlight);
}

.mat-cell {
  font-family: "Montserrat";
  color: #4d4d4d;
  font-size: 14px;
}

.mat-paginator {
  font-family: "Montserrat";
  font-size: 14px;
}

.mat-paginator .mat-paginator-page-size-select {
  text-align: center;
}

.mat-paginator .mat-paginator-page-size-select {
  width: 45px;
}

.mat-select-value-text {
  font-family: "MontserratLight";
  color: var(--detail);
}

.mat-step-label {
  font-family: "Montserrat";
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background: transparent;
}

.mat-checkbox-checked.mat-accent
.mat-checkbox-ripple
.mat-ripple-element {
  opacity: 0.03 !important;
  background: transparent !important;
}

.mat-checkbox-frame {
  border-width: 1px !important;
  border-radius: 0 !important;
}

mat-checkbox .mat-checkbox-checkmark-path {
  stroke: #4d4d4d !important;
}

.mat-checkbox-mixedmark { /*indeterminate state line*/
  background-color: #4d4d4d !important;
}

.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background-color: #4d4d4d !important;
}

.count {
  bottom: 0;
  justify-self: flex-end;
  margin-left: 10px;
  padding-bottom: 20px;
  color: #4d4d4d !important;
  font-size: 13px;
  font-family: "Montserrat";
}


.title-mid-text {
  font-size: 23px;
  --webkit-animation: fadeIn ease 0.5s;
  color: #1f1c23 !important;
  animation: fadeIn ease 0.5s;
  font-family: "MontserratLight";
  white-space: nowrap;
  display: block;
}

.title-small-text {
  display: block;
  font-size: 23px;
  --webkit-animation: fadeIn ease 0.5s;
  color: #1f1c23 !important;
  animation: fadeIn ease 0.5s;
  font-family: "MontserratLight";
  white-space: nowrap;
}

.id-text {
  font-size: 13px;
  margin-top: 5px;
  width: 400px;
  color: #808080;
  font-family: "MontserratLight";
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
  display: block;
}


.title-small {
  display: inline-block;
  font-size: 24px;
  vertical-align: top;
  margin-top: 10px;
  margin-left: 83px;
  position: absolute;
  --webkit-animation: fadeIn ease 0.5s;
  color: var(--title-grey) !important;
  animation: fadeIn ease 0.5s;
  font-family: "MontserratSemiBold";
  white-space: nowrap;
}


.page-title {
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
  font-size: 23px;
  margin-left: 15px;
  color: var(--main-color);
  font-family: "Montserrat";
}

.page-subtitle {
  font-size: 24px;
  margin-top: -4px !important;
  color: black !important;
  font-family: "Montserrat" !important;
  display: block;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.button-text {
  font-family: "Montserrat";
}

/*right floating buttons*/


.buttons-div {
  margin-top: -48px;
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
}

.buttons-right {
  float: right;
  display: flex;
  margin-right: -20px;
  margin-top: 20px;
}

.table-item-icon {
  font-weight: 100;
  color: rgba(34, 34, 34, 0.9);
  font-size: 23px;
}

.bold-table-item {
  font-family: "MontserratSemiBold";
  font-size: 14px;
  color: #4d4d4d;
}

.black-icon {
  color: var(--main-color);
}

.error-msg {
  padding: 10px 20px;
  width: 300px;
  margin: auto;
  font-family: "MontserratLight";
  display: flex;
  gap: 1rem;
  align-items: center;
}

/* image icons */
.list-icon {
  /* div for small list icons */
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--background-gray);
  display: flex;
}

.main-icon-button {
  display: inline;
  width: 60px;
  height: 60px;
  pointer-events: none;
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
}

.back {
  color: black;
  width: 48px;
  height: 48px;
  align-self: center;
}

.back-icon {

  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
}

.back-icon-button {
  transform: scale(2);
  font-size: 20px;
  color: #333333;
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
}

.title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
  position: relative;
  max-width: calc(100% - 40px);
  overflow: scroll;
}

.title-main-page-container {
  display: flex;
  align-items: center;
}

/* @media screen and (max-width: 1430px) {
  .title-container {
    max-width: calc(100% - 40px);
    overflow: scroll;
  }
} */

.page-icon-button {
  /* button for default icons */
  width: 60px;
  height: 60px;
  border-radius: 50%;
  pointer-events: none;
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
}

.add-button-empty {
  background-color: var(--green-color);
  color: white;
  width: 150px;
  height: 50px;
  font-size: 16px;
  font-family: "MontserratLight";
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
  background-color: var(--green-color);
  border-radius: 25px !important;
}

.add-button-empty-masive {
  background-color: white;
  color: var(--green-color);
  width: 150px;
  height: 50px;
  margin-top: 20px !important;
  font-size: 16px;
  font-family: "MontserratLight";
  border-radius: 25px !important;
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
}

.add-button.mat-fab {
  background-color: var(--green-color);
  margin-right: 44px;
  float: right;
  bottom: 70px;
  width: 40px;
  height: 40px;
  box-shadow: none;
}

.add-buttonAlternative.mat-fab {
  background-color: var(--green-color);
  margin-right: 24px;
  float: right;
  bottom: 85px;
  visibility: hidden;
}

.white-background-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  pointer-events: none;
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
  background-color: white !important;
  box-shadow: none !important;
  align-self: center;
}

.blue-button-icon {
  background-color: transparent !important;
  align-self: center;
}

button.mat-focus-indicator.main-icon-button.blue-button-icon.mat-fab.mat-button-base.mat-accent,
button.mat-focus-indicator.dialog-button.blue-button-icon.mat-fab.mat-button-base.mat-accent,
button.mat-focus-indicator.page-icon-button.blue-button-icon.mat-fab.mat-button-base.mat-accent {
  box-shadow: none;
  color: var(--main-color);
  border: 2px solid var(--main-color);
  width: 43px;
  height: 43px;
}

.grey-button-icon {
  background-color: var(--button-grey) !important;
  align-self: center;
}

.green-button-icon {
  background-color: var(--green-color) !important;
}

.action-button-icon {
  /*default icons*/
  display: flex;
  align-items: center;
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
}

.action-button-icon-delete {
  transform: scale(0.75);
  background-color: #c1272c;
  border: 2px solid white;
  margin-left: 43px;
  margin-top: 23px;

  border-radius: 50%;
  color: white;
  position: absolute;
  padding: 5px;
}

.small-image {
  /* image inside list-icon */
  width: 30px;
  margin: auto;
}

.small-image-zoom {
  /* image inside list-icon */
  width: 40px;
  margin: auto;
  border-radius: 50%;
}

.image {
  width: 40px;
  align-self: center;
}

.image-zoom {
  /* title page image for filled round image */
  width: 70px;
  height: 70px;
  border-radius: 50%;
  align-self: center;
  box-shadow: 3px 3px 6px 0px #e4d4d4;
}

/* divs */

.general-operations-div {
  display: flex;
  justify-content: space-between;
  min-width: 690px;
  padding-bottom: 8px;
}

.general-operations-buttons-div {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: center;
}

/* empty screen */

.empty-img {
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
  height: 250px;
}

.empty-lbl {
  color: var(--menu-item-grey);
  margin-bottom: 32px;
  margin-top: 20px;
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
  font-size: 24px;
  font-family: "MontserratLight";
}

.empty-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100%;
  width: 100%;
  text-align: center;
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
}


/* DASHBOARD */

.dashboard-card {
  height: 100px;
  width: 190px;
  margin-right: 22px;
  font-family: "Montserrat";
  text-align: center;
  box-shadow: none;
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
  border-radius: 10px;
  display: flex !important;
  flex-direction: column;
}

.dashboard-count {
  font-family: "Montserrat";
  color: var(--title);
  font-size: 40px;
  margin: auto;
  margin-bottom: 15px !important;
}

.dashboard-card-title {
  font-size: 16px;
  color: var(--title-grey);
  margin: auto;
  margin-top: 15px !important;
}

.dashboard-title {
  color: var(--title-grey);
  font-family: "MontserratSemiBold";
  font-size: 25px;
  margin-left: 273px;
  position: absolute;
}

.title-card {
  color: var(--title);
  font-family: "Montserrat";
  font-size: 19px;
  margin-bottom: 15px;
  margin-top: 5px !important;
}

svg.ngx-charts {
  font-family: "Montserrat";
  color: var(--title);
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.delete-paragraph {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-family: "Montserrat" !important;
  font-size: 15px;
  color: #4d4d4d;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  height: 0;
  background: transparent; /* Optional: just make scrollbar invisible */
}

.component {
  background-color: var(--background);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.details-component {
  background-color: var(--background);
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.dialog-component {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 39px;
  box-sizing: border-box;
  overflow-y: scroll;
  background-color: var(--background);

}

@media screen and (min-height: 730px) {
  .dialog-component {
    height: 100%;
    min-height: 555px;
  }

  .confirmation-dialog-component {
    height: 100%;
    min-height: 555px;
    padding: 2rem;
  }

  .medium-dialog .dialog-component {
    height: 100%;
    min-height: 494px;
  }
}

.stacked-component {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.table {
  width: 100%;
}

.tableResponsive {
  width: 100%;
  bottom: 10px;
  position: relative;
  flex-grow: 1;
  margin-top: 20px;
}

.tableResponsive mat-card,
.medium-size-table mat-card,
.tableDetails mat-card {
  height: 99%;
  margin-top: 25px;
}

.table thead {
  height: 10% !important;
}


.paginator-wrapper {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-end;
  height: 6vh;
  min-height: 70px;
}

.paginator-wrapper-loader {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
  bottom: 0;
  height: 6vh;
  min-height: 62px;
}

.paginator-wrapper-loader * {
  margin-bottom: 10px;
}

mat-paginator button.mat-focus-indicator.mat-tooltip-trigger.mat-icon-button.mat-button-base {
  color: #230c33;
}

mat-paginator .mat-select-arrow {
  color: #230c33 !important;
}

mat-paginator .mat-paginator-page-size-label,
mat-paginator .mat-paginator-range-label {
  font-family: "Montserrat";
  color: #4d4d4d;
  font-size: 13px;
}

mat-paginator .mat-form-field-flex {
  border-width: 0 !important;
}

.mat-icon-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, .26) !important;
}

td, th {
  border-bottom-width: 0;
  border-bottom-style: none !important;
}

.mat-elevation-z0 > .mat-button-wrapper {
  padding: 16px 0 !important;
  display: inline-block !important;
  line-height: 24px !important;
}

.action-button-icon-user {
  position: absolute;

  vertical-align: text-bottom;
}

.action-button-icon-edit {
  transform: scale(0.75);
  background-color: var(--green-color);
  border: 2px solid white;
  margin-left: 43px;
  margin-top: 23px;

  border-radius: 50%;
  color: white;
  position: absolute;
  padding: 5px;
}

.action-button-icon-add {
  background-color: var(--title);
  border-radius: 50%;
  color: white;
  margin-left: 40px;
  margin-top: 40px;
  position: absolute;
  transform: scale(0.55);
  padding: 5px;
}

.title .action-button-icon-add {
  transform: scale(0.75);
  background-color: var(--green-color);
  border: 2px solid white;
  margin-left: 43px;
  margin-top: 23px;
}

.title .mat-fab {
  width: 50px;
  height: 50px;
}

.confirm-title .mat-fab {
  width: 50px;
  height: 50px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header .mat-step-label {
  padding-top: 5px !important;
}

.mat-step-text-label {
  line-height: 1;
  margin-left: 10px;
  text-align: center;
  font-size: 11px;
  white-space: pre-line;
  margin-top: 16px;
  color: var(--disabled-green);
  font-family: "MontserratSemiBold";
}

.mat-sort-header-content {
  text-align: center;
  display: flex;
  align-items: center;
}

th {
  font-weight: bolder !important;
}

div {
  font-weight: bolder;
}

tr.mat-row.cdk-row.row.ng-star-inserted:hover {
  background-color: var(--light-green);
}

.tableDetails {
  flex-grow: 1;
  width: 100%;
  bottom: 10px;
  height: 48vh;
  min-height: 250px;
  margin-bottom: 20px;
}

.large-filter {
  height: 35px;
  width: 12rem;
  margin-top: 23px;
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
}

.date-filter {
  height: 35px;
  width: 16rem;
  margin-top: 23px;
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
}

.medium-filter {
  height: 35px;
  width: 8rem;
  margin-top: 23px;
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
}


.filter-wrapper {
  display: flex;
  width: 100%;
  gap: 20px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.medium-size-table {
  width: 100%;
  bottom: 10px;
  height: 60vh;
  min-height: 480px;
  margin-bottom: 20px;
}

.flex-container-vertical {
  min-width: 161px;
}

.flex-container-vertical-loading {
  display: flex;
  flex-direction: column;
  max-width: 15vw;
  margin: 10px 20px;
  margin-bottom: 5px;
  overflow: scroll;
  max-height: 40px;
  min-width: 161px;
}

.flex-container {
  overflow-x: auto;
  min-width: 1005px;
}

.last-button {
  margin-right: 0 !important;
}

mat-card {
  box-shadow: 0px 0px 5px 0px rgb(30 30 30 / 20%) !important;
}

.tableDetails > mat-card,
.medium-size-table > mat-card {
  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: 4px;
}

.flex-container-vertical-modal {
  max-width: none !important;
  max-height: none !important;
}


.confirmation-dialog-component {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: space-between;
  box-sizing: border-box !important;
}

.confirm-dialog-button {
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
  display: inline;
  box-shadow: none !important;
  color: black !important;
  border: 2px solid black !important;
}


.confirm-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.confirm-title-inner-div {
  display: flex;
  align-items: center;
}

.confirm-title .exit-button {
  top: -36px;
  left: 19px;
  background-color: transparent;
}


.confirm-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.confirm-buttons * {
  margin-right: 0px !important;
}

.button.confirm-cancel-button {
  background: transparent;
  color: var(--green-color);
  border: 2px solid var(--green-color);
}

.button.confirm-delete-button {
  background-color: #c1272c !important;
  color: #fff;
}

.button.confirm-delete-button:hover {
  background: #d75257 !important;
}

.button.confirm-cancel-button:hover {
  background: var(--light-green) !important;
}

.dialog-component .mat-form-field-subscript-wrapper {
  position: unset;
  max-width: 200px;
}


.dialog-component tr.mat-header-row {
  height: 38px;
}

.error-tooltip {
  background-color: var(--error-box);
  padding: 10px 20px;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  background-color: var(--error-box);
  width: 200px;
  margin: auto;
  font-family: "MontserratLight";
  border-color: var(--error-box-border);
  color: gray !important;
  margin: 5px !important;
  text-align: center;
}

.mat-form-field-disabled .mat-form-field-flex {
  background-color: var(--background-disabled) !important;
}

table.mat-calendar-table {
  min-width: unset !important;
}

.mat-calendar-body-selected {
  background-color: #03877A;
}

.mat-calendar-body-in-range::before {
  background: #03877a30;
}

.mat-calendar-body-cell-content.mat-calendar-body-today {
  background: transparent !important;
}

.mat-calendar-body-cell-content.mat-calendar-body-today:hover {
  background-color: #03877a30 !important;
}

td .mat-focus-indicator:hover {
  background-color: #03877a30 !important;
  transition: none !important;
}

td .mat-calendar-body-cell:after:hover {
  background-color: #03877a30 !important;
}

.mat-datepicker-toggle-active {
  color: #03877A !important;
}

.list-status-icon {
  background-repeat: no-repeat;
  display: inline-flex;
  fill: currentColor;
  height: 24px;
  width: 24px;
}

.dark {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;

}

.dark .mat-progress-spinner circle, .mat-spinner circle {
  stroke: var(--background-green);
}

.status-column .mat-sort-header-container {
  justify-content: center;
  position: relative;
  left: 6px;

}


.mat-horizontal-stepper-wrapper {
  width: 100%;
  height: 100%;
}

.material-symbols-outlined {
  font-variation-settings: 'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

.img-button {
  background-color: var(--green-color) !important;
  width: 40px !important;
  height: 40px !important;
  box-shadow: none !important;
  display: grid !important;
  place-items: center;
  cursor: default !important;
}

.img-button .mat-button-wrapper {
  transform: scale(0.95);
}

.paycorr-table {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}


.mat-card {
  background-color: var(--background-highlight) !important;
}

/* .error-msg {
  padding: 10px 20px;
  background-color: transparent;
  width: 300px;
  margin: auto;
  font-family: "MontserratMedium";
  display: flex;
  align-items: center;
  color: var(--red-color);
  gap: 10px;
  width: 100%;
  border: none;
} */

.error-icon {
  border-radius: 50%;
  background-color: var(--red-color);
  aspect-ratio: 1/1;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0px;
  text-align: center;
}

.error-icon mat-icon {
  color: white;
  font-size: 1rem;
  line-height: 1.5rem;

}

.mat-fab.mat-accent.mat-button-disabled {
  background-color: var(--disabled-green) !important;
  cursor: not-allowed;
}

.login-container {
  background-color: white;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  width: 100% !important;
  flex-grow: 1;
  align-items: center;
}

.mat-tab-group {
  flex-grow: 1;
}

.mat-tab-body-wrapper {
  height: 100%;
}
